.header-component {
  flex: 0 0 auto;
  z-index: 1;

  #d5-topbar{
    border-bottom: 1px solid #d5d9da;
    background-color: inherit;
    &.dx-toolbar .dx-toolbar-items-container .dx-toolbar-after, &.dx-toolbar .dx-toolbar-items-container .dx-toolbar-before{
      padding: 0 20px;
    }

    .top-bar-icon-wrapper {
      height: 100%;
      display: flex;

      .top-bar-icon {
        font-size: inherit;
      }
    }
  }
}
