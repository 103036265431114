* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@import "mixins";

html, body, #app {
  height: 100%;
}

body {
  /*background-color: #fafafa;*/
}

#app-frame {
  z-index: 1;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.dx-button {
  &[data-enable="true"] {
    .icon {
      color: #367FAC;
    }
  }
}

.tab-form {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  //padding-top: 8px;
  .group-container {
    height: inherit;
  }
}

/* buttonsForm style */
.table-toolbar-wrapper {
  display: flex;
  align-items: flex-start;
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-bottom: 8px;
  flex: 0 0 auto;

  .d5-table-toolbar {
    width: 100%;
    display: flex;
    flex-flow: row wrap;


    .toolbar-spacer {
      margin-top: 13px;
    }

    .toolbar-spacer-inside {
      margin-top: 0;
    }
  }
}

.custom-dropdown-menu {

  .custom-dropdown-caret {
    margin-left: 5px;
  }

  .dx-menu-item .dx-menu-item-content .dx-icon {
    margin-right: 3px;
  }
}

/* END buttonsForm style */

/* Form style */

.form {
  .dx-item-content {
    max-width: 100%;

    .detail-form-cont {
      height: 600px;
    }

    > .dx-item.dx-box-item {
      max-width: 50%;
    }
  }

  .dx-tabpanel-container {
    .dx-item-content {

      .detail-form-cont {
        max-height: 400px;
      }
    }
  }
}

.dx-popup-wrapper {
  .form {
    .dx-item-content {
      > .dx-item.dx-box-item {
        max-width: 100%;
      }
    }
  }
}

/* #59503
ужимается сильно в модальном окне фильтрации, на операции isanyof - закоментировал
.dx-tagbox-popup-wrapper {
  .dx-overlay-content {
    width: auto !important;
  }
}
*/

.bold {
  font-weight: bold;
}

.d5-toolbar {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
}

.d5-drawer-toolbar {
  flex: 0 0 auto;
}

.d5-toolbar-footer {
  flex-direction: column;
  align-items: flex-end;
}

.user-toolbar-wrap {
  position: relative;
}

.drawer-label-left {
  padding-left: 16px;
}

.d5-toolbar-footer_inner {
  flex-direction: row;
}

.d5-toolbar-footer_vers {
  width: 100%;
  padding: 0;
  font-size: 0.75rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
}

.dxx .dx-popup-content {
  padding: 0;
}

.user-menu-btn_wrap {
  width: 100%;
  padding: 10px 0;
}

.dx-drawer-panel-content .dx-widget {
  color: unset;
}

/**/

.logo-svg-wrapper {
  display: inline-flex;
  flex-shrink: 0;
  cursor: pointer;

  .logo-svg {
    width: auto;
    display: inline-block;
    font-size: inherit;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    user-select: none;
    flex-shrink: 0;

  }
}

.dx-treeview-node a {
  color: inherit;
  height: 100%;
  width: 100%;
  display: block;
}

//.dx-treeview-item .dx-icon {
//  width: auto !important;
//}

.d5-react-svg-icon {
  & > div {
    position: relative;
    height: 100%;

    svg {
      max-width: 100%;
      max-height: 100%;
    }
  }
}
