.filter-field-container {
  display: -webkit-flex;
  -webkit-align-items: center;
  display: flex;
  align-items: center;
  height: 100%;
  .dx-tag {
    margin: 0 0.5px;
  }

  .field-container-between {
    display: flex;
    width: 100%;
    flex-direction: column;

    .field-container {
      -webkit-flex: 1 1 auto; /* Safari 6.1+ */
      -ms-flex: 1 1 auto; /* IE 10 */
      flex: 1 1 auto;


      &.to {
        margin-left: 3px;
      }
    }
  }

  .operation-type-widget {
    border: none;

    .dx-button-content {
      padding: 0;
    }

    .dx-menu-item-selected {
      background: transparent;
    }
  }

  .d5-field-container {
    flex-grow: 1;
  }

  .dx-field-item-label-location-top {
    & + .dx-field-item-content {
      .filter-field-container {
        padding-left: 0 !important;
      }
    }
  }
}

.d5popover-with-list-li {
  display: -webkit-flex;
  -webkit-align-items: center;
  display: flex;
  align-items: center;

  &:hover {
    background-color: #f2f2f2;
  }

  .dx-icon {
    margin-right: 8px;
  }
}
