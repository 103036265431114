@import "table";

$tableMinHeight: 200px;
$tableMaxHeight: 350px;

.grid-form-edit-table {
  display: grid;
  overflow: hidden;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  .grid-container {
    display: grid;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 1.75rem;
    overflow: hidden;

    .grid-d5-table {
      overflow: hidden;
      min-width: 100%;
    }
  }
}

.grid-form-inline {
  .d5-accordion {
    .grid-form-edit-table {
      min-height: $tableMinHeight;
      max-height: $tableMaxHeight;
    }
  }
}
