@import "mixins";

.dx-overlay-wrapper, #main-content {
  .form-title {
    overflow: hidden;
    -o-text-overflow: ellipsis;
    -ms-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 1.3rem;
    margin: -6px -20px;
    padding: 6px 20px;
    line-height: 38px;
    display: flex;
    justify-content: space-between;
  }

  //Class "form" use in FormEdit & D5FormLayoutFiltersContainer Components
  .form {

    .maxWidth {
      &.-width50 {
        .dx-item-content.dx-box-item-content.dx-box-flex.dx-box.dx-widget.dx-visibility-change-handler.dx-collection > .dx-item.dx-box-item {
          max-width: 50%;
          min-width: 50%;
        }
      }

      &.-width33 {
        max-width: 33.33%;
        min-width: 33.33%;
      }

      &.-width25 {
        max-width: 25%;
        min-width: 25%;
      }

      &.-width20 {
        max-width: 20%;
        min-width: 20%;
      }
    }


    .dx-item {

      .dx-field-item-label-content {
        max-width: 320px;
        white-space: normal;

        .dx-field-item-label-text {
          /*color: #000;*/
        }
      }

      .detail-form {
        .dx-form-group-content {
          padding-top: 0;
          padding-bottom: 0;
          //margin-top: -19px;
        }
      }

      .dx-form-group-content {
        //#57343
        .dx-field-item {
          -ms-flex-align: center;
          -webkit-align-items: center;
          -webkit-box-align: center;
          align-items: center;

          & :first-child {
            &.dx-field-item-content-location-right {
              flex-grow: unset;
              width: auto;
            }
          }
        }
      }

      .dx-field-item-required-mark {
        color: #ef0a0a;
      }

      .dx-datebox {
        width: 100% !important;
      }
    }
  }

  .form-buttons-panel {
    float: right;

    .title-button {
      float: left;
      cursor: pointer;
      transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      font-size: .9rem;
      font-weight: 600;
      text-align: center;
      padding: 0 10px;

      &.disable {
        opacity: .5;
        user-select: none;
        cursor: default;
      }

      &:hover:not(.disable) {
        background-color: rgba(0, 0, 0, 0.08);
      }
    }
  }
}

.dx-overlay-wrapper {
  .form {
    margin: -20px -20px 0 -20px;
  }
}

#main-content {
  .form {
    height: 100%;

    .table-toolbar-wrapper {
      min-height: 51px;
    }
  }
}

.icon-margin-right {
  margin-right: 6px;
}
