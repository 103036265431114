.d5-left-topbar {
  border-bottom: 1px solid #d5d9da;
}

.layout-body {
  min-height: 0;
  flex: 1 1;

  &.dx-drawer-right {
    .dx-resizable {
      transform: none !important;
    }
  }

  .drawer-wrap {
    border-right: 1px solid #d5d9da;;
    display: flex;
    flex-direction: column;
    height: 100%;

    .d5-sidedrawer-treeview-wrap {
      flex: 1 0 0;
      overflow-y: auto;

      #d5-sidedrawer-treeview {
        padding: 8px 0 3px 8px;
        //white-space: nowrap;

        .d5-drawer-search-field {
          margin-right: 5px;
        }

        .dx-scrollable {
          width: 100%;
        }
      }
    }

    // ## Arrow customization

    .dx-treeview-toggle-item-visibility {
      right: 10px;
      left: auto;
    }

    .dx-rtl .dx-treeview-toggle-item-visibility {
      left: 10px;
      right: auto;
    }

    // ##

    .build-version {
      flex: 0 0 auto;
      text-align: right;
      font-size: .533rem;
    }
  }
}

.d5-drawer-right-btn {
  margin-right: 16px;
}
